import React from 'react';

function Features() {
  return (
    <div className="relative flex size-full min-h-screen flex-col">
      <main className="px-40 flex flex-1 justify-center py-5">
        <div className="layout-content-container flex flex-col max-w-[960px] flex-1">
          <div className="flex flex-col gap-10 px-4 py-10">
            <div className="flex flex-col gap-4">
              <h1 className="text-[#111418] text-[32px] font-bold leading-tight tracking-[-0.033em] max-w-[720px]">Features</h1>
            </div>

            <div className="flex flex-col gap-6">
              <h2 className="text-[#111418] text-xl font-bold leading-tight">Read Any New Attachment Sent to Your Email</h2>
              <p className="text-[#637588] text-base font-normal leading-normal">
                Automatically scan and process any new attachment received in your email. Whether it’s an invoice, a contract, or a receipt, Goffer AI can instantly analyze the content and extract relevant information.
              </p>
              <div className="bg-[#f0f2f4] rounded-lg p-6">
                <p className="text-[#111418] font-medium">How It Works:</p>
                <ul className="text-[#637588] list-disc list-inside">
                  <li>Connect your email account to Goffer AI.</li>
                  <li>Every time you receive an email with an attachment, Goffer AI will automatically process the document.</li>
                  <li>Extracted information is then presented in an easy-to-read format, and any relevant tasks or reminders are created for you.</li>
                </ul>
                <p className="text-[#111418] font-medium mt-4">Example Use Case:</p>
                <p className="text-[#637588]">Receive an invoice from a vendor, and Goffer AI will immediately capture the amount due, due date, and suggest setting a payment reminder.</p>
              </div>
            </div>

            <div className="flex flex-col gap-6">
              <h2 className="text-[#111418] text-xl font-bold leading-tight">Upload Documents</h2>
              <p className="text-[#637588] text-base font-normal leading-normal">
                Easily upload documents directly to Goffer AI for quick processing. Whether it's through your desktop, mobile device, or cloud storage, you can seamlessly integrate your files into the platform.
              </p>
              <div className="bg-[#f0f2f4] rounded-lg p-6">
                <p className="text-[#111418] font-medium">How It Works:</p>
                <ul className="text-[#637588] list-disc list-inside">
                  <li>Upload documents in various formats, including PDFs, Word files, and images.</li>
                  <li>Goffer AI will automatically analyze the content, extract key details, and organize your files.</li>
                  <li>Create tasks, set reminders, or get insights based on the document's content.</li>
                </ul>
                <p className="text-[#111418] font-medium mt-4">Example Use Case:</p>
                <p className="text-[#637588]">Upload a new contract to Goffer AI, and it will extract key terms, such as payment schedules and deadlines, while also setting up reminders for important dates.</p>
              </div>
            </div>

            <div className="flex flex-col gap-6">
              <h2 className="text-[#111418] text-xl font-bold leading-tight">Chat to Your Own Personal Assistant</h2>
              <p className="text-[#637588] text-base font-normal leading-normal">
                Engage in a natural conversation with your AI assistant, tailored to understand and respond to your specific needs. Whether you’re asking for a summary, setting up a task, or just need information from a document, your assistant is ready to help.
              </p>
              <div className="bg-[#f0f2f4] rounded-lg p-6">
                <p className="text-[#111418] font-medium">How It Works:</p>
                <ul className="text-[#637588] list-disc list-inside">
                  <li>Start a chat session with your AI assistant directly from the platform.</li>
                  <li>Ask questions about your documents, request tasks to be created, or get summaries of important details.</li>
                  <li>The assistant learns from your interactions, becoming more attuned to your preferences and needs over time.</li>
                </ul>
                <p className="text-[#111418] font-medium mt-4">Example Use Case:</p>
                <p className="text-[#637588]">Chat with your assistant to quickly get a summary of a lengthy report or ask it to create a task for an upcoming project deadline.</p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Features;
