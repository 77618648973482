import React, { useState } from 'react';
import * as endpoints from '../services/endpoints';  

const FileUpload = () => {
  const [uploading, setUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);

  const handleFileUpload = async (event) => {
    event.preventDefault();
    const files = event.target.files;
    
    console.log("Files selected:", files);
    
    if (!files || files.length === 0) {
      setUploadStatus({
        success: false,
        message: 'Please select at least one file.'
      });
      return;
    }

    setUploading(true);
    setUploadStatus(null);

    const formData = new FormData();
    Array.from(files).forEach(file => {
      console.log("Appending file to FormData:", file.name, file.size);
      formData.append('file', file);
    });

    try {
      console.log("Sending upload request with FormData:", {
        fileCount: files.length,
        totalSize: Array.from(files).reduce((acc, f) => acc + f.size, 0)
      });
      
      await endpoints.uploadFile(formData);
      setUploadStatus({
        success: true,
        message: `Successfully uploaded ${files.length} file(s)`
      });
    } catch (error) {
      console.error("Upload error details:", {
        status: error.response?.status,
        statusText: error.response?.statusText,
        data: error.response?.data,
        headers: error.response?.headers
      });
      
      setUploadStatus({
        success: false,
        message: error.response?.data?.message || 'Upload failed'
      });
    } finally {
      setUploading(false);
      // Reset file input
      event.target.value = '';
    }
  };

  return (
    <div className="upload-section">
      <form>
        <div className="input-button-wrapper">
          <input
            type="file"
            onChange={handleFileUpload}
            accept=".txt,.pdf,.doc,.docx,.xlsx,.csv,.png"
            multiple
            disabled={uploading}
          />
        </div>
      </form>

      {uploading && (
        <div className="upload-status info">
          <i className="fas fa-spinner fa-spin"></i> Uploading...
        </div>
      )}

      {uploadStatus && (
        <div className={`upload-status ${uploadStatus.success ? 'success' : 'error'}`}>
          <p>{uploadStatus.message}</p>
          {uploadStatus.success && (
            <ul className="uploaded-files-list">
              <li>Files uploaded successfully</li>
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
