import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Onboarding() {
  const [option, setOption] = useState('');
  const [emailConfig, setEmailConfig] = useState({
    email_host: '',
    email_address: '',
    email_password: '',
    email_host_port: ''
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleOptionChange = (e) => {
    setOption(e.target.value);
  };

  const handleEmailConfigChange = (e) => {
    setEmailConfig({ ...emailConfig, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (option === 'email') {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/onboarding`,
          { option, ...emailConfig },
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('jwt_token')}`,
              'Content-Type': 'application/json'
            }
          }
        );
        if (response.status === 200) {
          console.log('Onboarding successful:', response.data);
          navigate('/dashboard');
        }
      } catch (error) {
        console.error('Onboarding error:', error.response?.data?.error || error.message);
        setError(error.response?.data?.error || 'An error occurred during onboarding');
      }
    } else if (option === 'upload') {
      // Redirect to dashboard for manual upload
      navigate('/dashboard');
    }
  };

  return (
    <div className="onboarding-container">
      <h2>Welcome to Goffer AI</h2>
      <p>Please choose how you'd like to start:</p>
      {error && <div className="error-message">{error}</div>}
      <form onSubmit={handleSubmit}>
        <div>
          <label>
            <input
              type="radio"
              value="email"
              checked={option === 'email'}
              onChange={handleOptionChange}
            />
            Configure email for automatic document processing
          </label>
        </div>
        <div>
          <label>
            <input
              type="radio"
              value="upload"
              checked={option === 'upload'}
              onChange={handleOptionChange}
            />
            I'll upload documents manually
          </label>
        </div>

        {option === 'email' && (
          <div className="email-config">
            <input
              type="text"
              name="email_host"
              value={emailConfig.email_host}
              onChange={handleEmailConfigChange}
              placeholder="Email Host"
              required
            />
            <input
              type="email"
              name="email_address"
              value={emailConfig.email_address}
              onChange={handleEmailConfigChange}
              placeholder="Email Address"
              required
            />
            <input
              type="password"
              name="email_password"
              value={emailConfig.email_password}
              onChange={handleEmailConfigChange}
              placeholder="Email Password"
              required
            />
            <input
              type="number"
              name="email_host_port"
              value={emailConfig.email_host_port}
              onChange={handleEmailConfigChange}
              placeholder="Email Host Port"
              required
            />
          </div>
        )}

        <button type="submit">Continue</button>
      </form>
    </div>
  );
}

export default Onboarding;
