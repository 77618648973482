import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { register } from '../services/endpoints';

function Register() {
  const [formData] = useState({
    first_name: '',
    last_name: '',
    username: '',
    email: '',
    mobile_phone: '',
    password: '',
    confirm_password: ''
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Debug log the actual form data
    console.log('Raw form data:', {
      first_name: e.target.first_name.value,
      last_name: e.target.last_name.value,
      username: e.target.username.value,
      email: e.target.email.value,
      mobile_phone: e.target.mobile_phone.value,
      password: e.target.password.value,
    });

    // Create registration data from form values directly
    const registrationData = {
      first_name: e.target.first_name.value,
      last_name: e.target.last_name.value,
      username: e.target.username.value,
      email: e.target.email.value,
      mobile_phone: e.target.mobile_phone.value,
      password: e.target.password.value,
    };

    console.log('Registration data:', registrationData);

    // Validate required fields
    const requiredFields = ['email', 'password', 'first_name', 'last_name', 'username'];
    const emptyFields = requiredFields.filter(field => !registrationData[field]);
    
    if (emptyFields.length > 0) {
      setError(`Please fill in: ${emptyFields.join(', ')}`);
      return;
    }

    if (e.target.password.value !== e.target.confirm_password.value) {
      setError("Passwords don't match");
      return;
    }

    try {
      console.log('Sending registration data:', registrationData);
      const response = await register(registrationData);
      console.log('Registration response:', response.data);
      
      if (response.data.access_token) {
        localStorage.setItem('jwt_token', response.data.access_token);
        navigate('/onboarding');
      } else {
        setError('Registration successful but no token received');
      }
    } catch (error) {
      console.error('Registration error:', error.response?.data || error);
      setError(error.response?.data?.error || 'An error occurred during registration');
    }
  };

  return (
    <div className="flex min-h-screen items-center justify-center">
      <div className="w-[400px] bg-white rounded-lg p-8 shadow-sm">
        <h2 className="text-[#1980e6] text-base font-medium text-center">Register for Goffer AI</h2>
        
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
            {error}
          </div>
        )}
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="text-sm text-[#111418]">First Name *</label>
            <input 
              className="w-full mt-1 p-2 border border-[#dce0e5] rounded-lg"
              type="text"
              name="first_name"
              defaultValue={formData.first_name}
              placeholder="First Name"
              required
            />
          </div>

          <div>
            <label className="text-sm text-[#111418]">Last Name *</label>
            <input 
              className="w-full mt-1 p-2 border border-[#dce0e5] rounded-lg"
              type="text"
              name="last_name"
              defaultValue={formData.last_name}
              placeholder="Last Name"
              required
            />
          </div>

          <div>
            <label className="text-sm text-[#111418]">Username *</label>
            <input 
              className="w-full mt-1 p-2 border border-[#dce0e5] rounded-lg"
              type="text"
              name="username"
              defaultValue={formData.username}
              placeholder="Username"
              required
            />
          </div>

          <div>
            <label className="text-sm text-[#111418]">Email *</label>
            <input 
              className="w-full mt-1 p-2 border border-[#dce0e5] rounded-lg"
              type="email"
              name="email"
              defaultValue={formData.email}
              placeholder="Email"
              required
            />
          </div>

          <div>
            <label className="text-sm text-[#111418]">Mobile Phone</label>
            <input 
              className="w-full mt-1 p-2 border border-[#dce0e5] rounded-lg"
              type="tel"
              name="mobile_phone"
              defaultValue={formData.mobile_phone}
              placeholder="Mobile Phone"
            />
          </div>

          <div>
            <label className="text-sm text-[#111418]">Password *</label>
            <input 
              className="w-full mt-1 p-2 border border-[#dce0e5] rounded-lg"
              type="password"
              name="password"
              defaultValue={formData.password}
              placeholder="Password"
              required
            />
          </div>

          <div>
            <label className="text-sm text-[#111418]">Confirm Password *</label>
            <input 
              className="w-full mt-1 p-2 border border-[#dce0e5] rounded-lg"
              type="password"
              name="confirm_password"
              defaultValue={formData.confirm_password}
              placeholder="Confirm Password"
              required
            />
          </div>

          <button 
            type="submit"
            className="w-full bg-[#1980e6] text-white py-2 rounded-lg hover:bg-[#1666b8] transition-colors"
          >
            Register
          </button>
        </form>

        <p className="text-center mt-4 text-sm text-[#637588]">
          Already have an account?{' '}
          <Link to="/login" className="text-[#1980e6] hover:underline">
            Log in
          </Link>
        </p>
      </div>
    </div>
  );
}

export default Register;