import React from 'react';
import Navbar from './Navbar';
import AuthenticatedNavbar from './AuthenticatedNavbar';
import Footer from './Footer';

function Layout({ children }) {
  const isAuthenticated = !!localStorage.getItem('jwt_token');

  return (
    <div className="flex flex-col min-h-screen bg-white" style={{fontFamily: 'Inter, "Noto Sans", sans-serif'}}>
      {isAuthenticated ? <AuthenticatedNavbar /> : <Navbar />}
      <main className="flex-grow">
        {children}
      </main>
      <Footer />
    </div>
  );
}

export default Layout;
