import React from 'react';
import FileUpload from './FileUpload';
import Query from './Query';
import '../styles/Dashboard.css';

const Dashboard = () => {
  return (
    <div className="dashboard-container">
      <div className="dashboard-content">
        <div className="welcome-section">
          <h1>Document Assistant Dashboard</h1>
          <p>Automate tasks, organize content, and extract insights from documents with AI. No code required.</p>
        </div>

        <div className="features-grid">
          <div className="feature-card">
            <h2>
              <i className="fas fa-upload"></i>
              Document Upload
            </h2>
            <p>Upload and process your documents securely</p>
            <FileUpload />
          </div>

          <div className="feature-card">
            <h2>
              <i className="fas fa-brain"></i>
              AI Query Interface
            </h2>
            <p>Ask questions and get insights from your documents</p>
            <Query />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
