import api from './api';

// Authentication
export const login = async (credentials) => {
  return await api.post('/api/login', credentials);
};

// File operations
export const uploadFile = async (formData) => {
  console.log("uploadFile called with formData:", {
    hasFile: formData.has('file'),
    entries: Array.from(formData.entries()).map(([key, value]) => ({
      key,
      type: value instanceof File ? 'File' : typeof value,
      size: value instanceof File ? value.size : null
    }))
  });
  
  return await api.post('/api/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    timeout: 30000,
    maxContentLength: Infinity,
    maxBodyLength: Infinity
  });
};

// Queries
export const queryDocuments = async (question) => {
  return await api.post('/api/query', { user: question });
};

// register a new user
export const register = async (userData) => {
  return await api.post('/api/register', userData);
};

// Add other endpoints as needed...
